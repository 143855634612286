exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-section-home-tsx": () => import("./../../../src/pages/blog/SectionHome.tsx" /* webpackChunkName: "component---src-pages-blog-section-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-website-components-price-feed-tsx": () => import("./../../../src/pages/website/components/PriceFeed.tsx" /* webpackChunkName: "component---src-pages-website-components-price-feed-tsx" */),
  "component---src-pages-website-components-toggle-js": () => import("./../../../src/pages/website/components/Toggle.js" /* webpackChunkName: "component---src-pages-website-components-toggle-js" */),
  "component---src-pages-website-home-js": () => import("./../../../src/pages/website/home.js" /* webpackChunkName: "component---src-pages-website-home-js" */),
  "component---src-pages-website-sections-products-index-tsx": () => import("./../../../src/pages/website/sections/products/index.tsx" /* webpackChunkName: "component---src-pages-website-sections-products-index-tsx" */),
  "component---src-pages-website-sections-products-perpetuals-tsx": () => import("./../../../src/pages/website/sections/products/Perpetuals.tsx" /* webpackChunkName: "component---src-pages-website-sections-products-perpetuals-tsx" */),
  "component---src-pages-website-sections-section-governance-tsx": () => import("./../../../src/pages/website/sections/SectionGovernance.tsx" /* webpackChunkName: "component---src-pages-website-sections-section-governance-tsx" */),
  "component---src-pages-website-sections-section-main-tsx": () => import("./../../../src/pages/website/sections/SectionMain.tsx" /* webpackChunkName: "component---src-pages-website-sections-section-main-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

